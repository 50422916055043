import React from "react"
import SingleLayout from '../components/layouts/SingleLayout_10'
import Seo from "../components/seo"

const Contact = () => {
  return (
    <>
      <SingleLayout >
        <Seo 
          title="Contact Us" 
          description="Contact us for any query or feedback. You can also contact us if you want any help or guidance." 
          image="/images/about.jpg"
          />
        <div className="w-lg-60 mx-lg-auto">
          <h1>Contact Us</h1>

          <iframe 
          src="https://docs.google.com/forms/d/e/1FAIpQLSdmOMjCPenD4mHZTRTBFuGdrbgCLgHkg2Y2A90USr7MDvjsQw/viewform?embedded=true" 
          title="jsx-a11y/iframe-has-title"
          width="640" height="574" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div>
      </SingleLayout>
    </>
  )
}

export default Contact